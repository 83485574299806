
import { defineComponent, computed, ref } from 'vue'
import { useStore } from 'vuex'
import AppButton from '@/components/atoms/AppButton.vue'
import { postDataCredential } from '@/helpers/util/webApiUtil'

const BASE_URL = process.env.VUE_APP_DMS_API_BASE_URL

export default defineComponent({
  name: 'OuterTemplateDownload',
  components: {
    AppButton,
  },
  props: {
  },
  setup (props) {
    const store = useStore()
    const loginUser = computed(() => store.getters.loginUser)
    const businessRoleGroupId = loginUser.value.businessRoleGroupId
    const selectValue = ref(businessRoleGroupId === '0003' ? '0001' : businessRoleGroupId === '0004' ? '0002' : businessRoleGroupId === '0005' ? '0003' : '')
    return {
      props,
      selectValue,

      async download () {
        const url = `${BASE_URL}/outer/metadata/template/download`
        const body = JSON.stringify({
          outerMetadataTemplateType: selectValue.value,
        })
        console.log(body)
        const response = await postDataCredential(url, body, true)
        if (response.status === 200) {
          const blob = await window
            .fetch('data:;base64,' + response.data.fileValue)
            .then(res => res.blob())
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = response.data.fileName
          link.click()
        }
      },
    }
  },
})
