
import { defineComponent, ref, computed } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import FileUpload from '@/components/organisms/dms/OuterFileInput/FileUpload.vue'
import TemplateDownload from '@/components/organisms/dms/OuterFileInput/TemplatesDownload.vue'
import DssIcon from '@/components/atoms/DssIcon.vue'
import AppButton from '@/components/atoms/AppButton.vue'
import { postDataCredential } from '@/helpers/util/webApiUtil'
import { s3upload } from '@/data/dms/s3upload'

const BASE_URL = process.env.VUE_APP_DMS_API_BASE_URL

export default defineComponent({
  name: 'OuterFileInput',
  components: {
    TemplateDownload,
    FileUpload,
    DssIcon,
    AppButton,
  },
  props: {
  },
  setup () {
    const router = useRouter()
    const store = useStore()
    const file = ref<FileList>()
    const errMsgFlg = computed(() => { return file.value ? file.value[0].name.split('.').pop() !== 'zip' : false })

    const setLoading = async (flag: boolean) => {
      await store.dispatch('setLoading', flag)
    }

    return {
      router,
      file,
      errMsgFlg,

      async send () {
        setLoading(true)
        const uploadFile = {
          fileName: file.value![0].name,
          s3UploadFilePath: '',
          fileData: file.value![0],
        }
        await s3upload([uploadFile])
        const s3UploadFilePathList = (store.getters.s3UploadFileList)[0].s3UploadFilePath
        const url = `${BASE_URL}/outer/item/bulk/entry`
        const body = JSON.stringify({
          s3UploadFilePathList: [s3UploadFilePathList],
        })
        const response = await postDataCredential(url, body, true)
        setLoading(false)
        if (response.status === 200) {
          await router.push({ name: 'OuterFileCompletePage', params: { confirmed: 'true' } })
        }
      },
    }
  },
})
