
import { defineComponent } from 'vue'
import OuterFileInput from '@/components/organisms/dms/OuterFileInput/OuterFileInput.vue'
import Breadcrumb from '@/components/organisms/dms/common/Breadcrumb.vue'
import StopSend from '@/components/organisms/dms/common/StopSend.vue'
export default defineComponent({
  components: {
    OuterFileInput,
    Breadcrumb,
    StopSend,
  },
  setup () {
    const list = [
      { text: 'ファイルをアップロード', numFlag: true },
      { text: '完了', numFlag: false },
    ]
    return {
      list,
    }
  },
})
