
import { defineComponent, ref, computed } from 'vue'
import TextAlert from '@/components/molecules/TextAlert.vue'
import InputImageFile from '@/components/molecules/InputImageFile.vue'
import DssIcon from '@/components/atoms/DssIcon.vue'
import Message from '@/components/organisms/dms/common/Message.vue'

export default defineComponent({
  name: 'FileUpload',
  components: {
    TextAlert,
    InputImageFile,
    DssIcon,
    Message,
  },
  props: {
    modelValue: {
      type: FileList,
    },
    errMsgFlg: {
      type: Boolean,
    },
  },
  setup (props, { emit }) {
    const isDisplay = ref(true)
    const messages = ref<{ id: string, type: string, text: string}[]>([])
    const removeMessage = (id: string) => {
      const ids = messages.value.map(a => a.id)
      const index = ids.indexOf(id)
      messages.value.splice(index, 1)
    }

    const file = computed({
      get: () => props.modelValue,
      set: (file) =>
        emit('update:modelValue', file),
    })

    return {
      props,
      file,
      isDisplay,
      messages,
      removeMessage,

      fileUpload (fileList: FileList) {
        if (!fileList[0]) return
        file.value = fileList
        isDisplay.value = true
        messages.value.push({
          id: String(messages.value.length + 1),
          type: 'success',
          text: `${fileList[0].name} をアップロードしました。`,
        })
      },
    }
  },
})
